import {PageHeader} from "antd";
import {useLocation, useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import OutstandingTable from "../../../components/organisms/report/outstanding/outstanding_table";

const OutstandingPage = () => {

  let navigate = useNavigate();
  const location = useLocation()
  let { userId } = useParams();

  return (<div>
    <PageHeader
      className="p-0 m-2"
      onBack={() => navigate(-1)}
      title={'Outstanding'}
    />

    <OutstandingTable userId={userId} />
  </div>)
}

export default OutstandingPage;