import axios from 'axios';
import { unAuthenticateService } from "../service/UnauthenticateService";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: process.env.NODE_ENV != "production",
  // headers: {'Accept': 'application/json', 'content-type': 'application/json'},
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
});


axiosInstance.interceptors.request.use(config => {
  config.headers['Accept'] = 'application/json'
  config.headers['content-type'] = 'application/json'


  // let token = document.head.querySelector('meta[name="csrf-token"]');
  // config.headers['X-CSRF-TOKEN'] = token.content;

  // config.headers['X-Requested-With'] = 'XMLHttpRequest'
  let cookieArray = document.cookie.split(";");
  // this can probably be improved by using a regex.. but this works for now
  for(var i = 0; i < cookieArray.length; i++) {
    let cookiePair = cookieArray[i].split("=");
    //
    // if(cookiePair[0].trim() == 'XSRF-TOKEN-AGENT') {
    //   config.headers['X-XSRF-TOKEN-AGENT'] = decodeURIComponent(cookiePair[1]);
    // }

    if(cookiePair[0].trim() == 'token-agent') {
      config.headers['Authorization'] = `Bearer ${decodeURIComponent(cookiePair[1])}`
    }

  }

  return config;
}, function (error) {
  return Promise.reject(error);
});
delete axiosInstance.defaults.headers.common['X-XSRF-TOKEN']

axiosInstance.interceptors.response.use((response) => {

  const ignoreTransformUrls = ['/sanctum/csrf-cookie', '/api/login', '/login'];

  if (ignoreTransformUrls.includes(response.config.url)) {
    return response;
  }
  if (response?.data?.status === 'success') {
    if (response?.data?.data) return response.data.data

    if (response?.data) return response.data
  }
  return Promise.reject("something went wrong");
  }, async function (error) {
    if (error.config.url !== '/agent/user' &&
        (error.response.status === 401 || error.response.status === 411 || error.response.status === 419)
    ) {
      unAuthenticateService.sendUnAuthenticate();
      // window.location.href = '/login'
    }
    return Promise.reject(error?.response?.data?.message ?? error.message);
  })

export default axiosInstance;
// export { AxiosInterceptor };