import http from "../http";

export default (axios, base) => ({
  csrf: () => {
    return http.get(`${base}/sanctum/csrf-cookie`)
  },
  logIn: (data) => {
    return http.post(`${base}/login`, data);
  },
  logOut: () => {
    return http.post(`${base}/logout`);
  },
  signInVerify: (data) => {
    return http.post(`${base}/api/signInVerify`, data);
  },
  refreshTokens: (data) => {
    return http.post(`${base}/api/refreshTokens`, data);
  },
});
