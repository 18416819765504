import http from "../http";

export default (axios, base) => ({
  items: () => {
    return http.get(`${base}`);
  },
  create: (name) => {
    return http.put(`${base}/create`, {name: name})
  }
});
