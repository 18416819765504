import React from 'react';
import {AuthProvider} from "./hooks/AuthContext";
import DelegatePage from "./pages/delegate";

const App = () => {
  return (
      <AuthProvider>
        <DelegatePage />
      </AuthProvider>
  )
}

export default App;
