import http from "../http";

export default (axios, base) => ({
  detail: () => {
    return http.get(`${base}`);
  },
  changePassword: (data) => {
    return http.post(`${base}/change_password`, data)
  }
});
