import React from 'react';
import LoginPage from "./auth/login";
import {useAuth} from "../hooks/AuthContext";

const GuardedRoute = ({ children }) => {
  const {isAuthenticate} = useAuth(); // Your hook to get login status

  if (!isAuthenticate) {
    return <LoginPage />;
  }
  return children;
}
export default GuardedRoute;