import {Menu, MenuProps, Layout} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useTranslation, Trans} from 'react-i18next';

import {
  FileTextOutlined,
  PieChartOutlined,
  TeamOutlined,
  SettingOutlined,
  UnlockOutlined,
  LogoutOutlined,
  FundViewOutlined, SnippetsOutlined, UserAddOutlined, SolutionOutlined, FileDoneOutlined
} from "@ant-design/icons";
import {useAuth} from "../../../hooks/AuthContext";


type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

function aHref(label: string, link: string): React.ReactNode {
  return (
    <a href={link} onClick={event => event.preventDefault()} >
      {label}
    </a>
  );
}


type Props = {
  handleLogout: () => void
}

const SiderLayout = ({handleLogout}: Props) => {

  const {t} = useTranslation();

  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  const {isAdmin, isSub, canAccount, canCash, user, onlineUserCount} = useAuth()

  const items: MenuItem[] = [
    getItem(aHref(t('dashboard'), '/'), '/', <PieChartOutlined/>),
    getItem(aHref(t('userOnlineN', {onlineUserCount}), '/online'), '/online', <UserAddOutlined/>),

    getItem(aHref(t('jackpot'), '/jackpot'), '/jackpot', <SnippetsOutlined/>),
    getItem(t('report'), 'report', <FileTextOutlined/>, [
      canCash ? getItem(aHref(t(`1. ${t('cash')}`), '/report/cash'), '/report/cash') : null,
      getItem(aHref(t(`2. ${t('winLose')}`), '/report/winlose'), '/report/winlose'),
      getItem(aHref(t(`3. ${t('depositWithdraw')}`), `/report/cash/detail/${user?.id}`), `/report/cash/detail/${user?.id}`),
    ]),
    getItem(`${t('report')} 2023`, 'report_2023', <FileDoneOutlined/>, [
      canCash ? getItem(aHref(t(`1. ${t('cash')}`), '/report/old_cash'), '/report/old_cash') : null,
      getItem(aHref(t(`2. ${t('winLose')}`), '/report/old_winlose'), '/report/old_winlose'),
    ]),
    canAccount ? getItem(t('account'), '/account', <TeamOutlined/>, [
          getItem(aHref(t(`1. ${t('list')}`), '/account/list'), '/account/list'),
          !isSub ? getItem(aHref(t(`2. ${t('sub')}`), '/account/sub'), '/account/sub') : null,
          isAdmin && !isSub ? getItem(aHref(t(`3. ${t('group')}`), '/account/group'), '/account/group') : null,
          isAdmin && !isSub ? getItem(aHref(t(`4. ${t('approve')}`), '/account/approve'), '/account/approve') : null,
          isAdmin ? getItem(aHref(t(`5 ${t('admin')}`), '/account/admin'), '/account/admin') : null
        ]
    ) : null,
    isAdmin && !isSub ? getItem(t('setting'), '/setting', <SettingOutlined/>, [
          getItem(aHref(`1. ${t('game')}`, '/setting/game'), '/setting/game'),
          getItem(aHref(`2. ${t('jackpot')}`, '/setting/jackpot'), '/setting/jackpot'),
          getItem(aHref(`3. ${t('location')}`, '/setting/location'), '/setting/location'),
          getItem(aHref(`4. ${t('system')}`, '/setting/system'), '/setting/system')
        ]
    ) : null,
    isAdmin || (isAdmin && isSub) ? getItem(t('tax'), 'tax', <SolutionOutlined/>, [
          getItem(aHref(`1. Account`, '/tax/account/filter'), '/tax/account/filter'),
        ]
    ) : null,
    getItem(t('log'), 'log', <FundViewOutlined/>, [
      getItem(aHref(`1. ${t('member_online')}`, 'member_online'), 'member_online'),
      getItem(aHref(`2. ${t('activity')}`, 'activity'), '/activity'),
    ]),
    getItem(aHref(t('changePassword'), '/user/change_password'), '/user/change_password', <UnlockOutlined/>),
    getItem(aHref(t('logout'), '/logout'), '/logout', <LogoutOutlined/>),
  ];

  const onClick: MenuProps['onClick'] = e => {
    if (e.key == '/logout') {
      handleLogout()
      return;
    }
    navigate(e.key)
  };
  return (
      <Layout.Sider theme="light" collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        <div className="logo h-14"/>
        <Menu onClick={onClick} theme="light" defaultSelectedKeys={['1']} mode="inline" items={items}/>
      </Layout.Sider>
  )
}

export default SiderLayout