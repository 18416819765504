import React from "react";

function LoginTitle() {
  return (
    <div>
      <img className="mx-auto w-auto" src="/logo_icon.png" style={{height:120, width:120}}
           alt="Workflow"/>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
        Sign in to your account
      </h2>
    </div>
  )
}

export default LoginTitle;