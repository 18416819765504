import {useLocation} from "react-router";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import api from "../../../api";
import AccountFilterTable from "../../../components/organisms/account/account_filter_table";

const AccountFilterPage = () => {

  const location = useLocation()
  let {userId} = useParams();

  const [loading, setLoading] = useState();
  const [items, setItems] = useState([]);
  const [parentRoleName, setParentRoleName] = useState();

  useEffect(() => {
    loadData();
  }, [location.key]);

  const loadData = async () => {
    setLoading(true);
    const {response, error} = await api.account.filter(userId);
    if (!error) {
      var no = 0;
      const data = response?.users.map(item => {
        item.key = item.id
        item.no = no + 1;
        no++;
        return item
      })
      setItems(data)
      setParentRoleName(response.parent_role);
    }
    setLoading(false);
  }

  return (<div>
    <AccountFilterTable loading={loading} items={items} parentRoleName={parentRoleName}/>
  </div>)
}
export default AccountFilterPage;