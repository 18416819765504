import React from "react";
import {Button, Form, Input} from "antd";

type Props = {
  handleSubmit: (values: any) => void,
  loading: boolean,
  error?: any
}
function LoginForm({handleSubmit, loading, error} : Props) {

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }}
          onFinish={handleSubmit}  autoComplete="off" layout={'vertical'}>
      <Form.Item className={`${error ? 'mb-1 pb-0' : ''} `} label="Login ID" name="username" rules={[{ required: true }]}>
        <Input type="text" name="username" id="username" autoComplete="given-name" placeholder="Login ID"
               className={`${error ? 'border-red-500 mb-0 ' : 'border-gray-300 focus:border-indigo-500'}  border-red-500 mt-1 pl-4 h-10 bg-gray-100 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm rounded-md`}/>
      </Form.Item>
      {error && <div className="text-red-600 mb-4">The credential does not match</div>}


      <Form.Item label="Password" name="password" rules={[{ required: true }]}>
        <Input type="password" name="password" id="password" autoComplete="given-name" placeholder="******"
               className="mt-1 pl-4 h-10 bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>


      </Form.Item>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input id="remember_me" name="remember_me" type="checkbox"
                 className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>
          <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
            Remember me
          </label>
        </div>

        <div>
          <Button htmlType="submit" loading={loading} type='primary'
                  className="group relative w-full flex justify-center border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
            Sign in
          </Button>
        </div>

      </div>
    </Form>
  )
}

export default LoginForm