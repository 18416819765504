import {Button, Table, Tag} from 'antd'
import {useNavigate} from "react-router";
import React from "react";
import {useAuth} from "../../../hooks/AuthContext";

const {Column} = Table;
const AccountFilterTable = ({loading, items, parentRoleName}) => {

  let navigate = useNavigate();

  const userSelected = (e, user) => {
    e.preventDefault()
    if(parentRoleName != 'agent') {
      navigate(`/account/list/filter/${user.id}`)
    }
  }

  return (
    <Table className='mt-4' size="small"
           loading={loading}
           rowKey='id'
           bordered
           dataSource={items}>
      <Column title='No' dataIndex='no' key='no' align='center'></Column>
      <Column title='Name' dataIndex='name' key='name' align='center'></Column>
      <Column title='Login ID' dataIndex='username' key='username' align='center' render={(_, record) =>
        <a className='underline' onClick={(e) => userSelected(e, record)}>{record.username?.toUpperCase() ?? ''}</a>
      }></Column>
      <Column title='Balance' dataIndex={['credit', 'balance']} key='balance' align='right'
              render={text =>
                <span>{Number(text).toLocaleString()}</span>
              }></Column>

      <Column title='Request' dataIndex='status' key='balance' align='center'
              render={(_, {status}) => {
                const color = status === 'APPROVED' ? 'green' : status === 'REJECTED' ? 'red' : null;
                return <Tag color={color}>
                  {status.toUpperCase()}
                </Tag>
              }}></Column>
      <Column title='Active' dataIndex='active' key='active' align='center'
              render={(_, {active}) => {
                const color = active === 1 ? 'green' : null;
                return <Tag color={color}>
                  {active === 1 ? 'Active' : 'Disable'}
                </Tag>
              }}></Column>
      <Column title='Create By' dataIndex={['created_by', 'username']} key='created_by' render={(_, record) =>
        <a className='underline' onClick={(e) => userSelected(e, record)}>{record?.created_by?.username?.toUpperCase() ?? ''}</a>
      }></Column>
      <Column title='Create At' dataIndex='created_at' key='created_at'></Column>
      <Column title='Last login' dataIndex='last_login' key='last_login'></Column>
    </Table>
  )
}

export default AccountFilterTable