import axiosInstance from "./axios";

class HTTPResult {
  constructor(response, error) {
    this.response = response;
    this.error = error;
  }
}

function createResolveResult(response) {
  return new HTTPResult(response, null);
}

function createRejectResult(error) {
  return new HTTPResult(null, error);
}

class HTTPHandler {
  handleRequest(ajaxOptions) {
    // delete axiosInstance.defaults.headers.common["X-XSRF-TOKEN"];
    delete axiosInstance.defaults.xsrfCookieName
    return axiosInstance
      .request(ajaxOptions)
      .then(createResolveResult, createRejectResult);
  }

  get(url, config = {}) {
    return this.handleRequest({
      ...config,
      method: "get",
      url
    });
  }

  delete(url, config = {}) {
    return this.handleRequest({
      ...config,
      method: "delete",
      url
    });
  }

  head(url, config = {}) {
    return this.handleRequest({
      ...config,
      method: "head",
      url
    });
  }

  options(url, config = {}) {
    return this.handleRequest({
      ...config,
      method: "options",
      url
    });
  }

  post(url, data, config = {}) {
    return this.handleRequest({
      ...config,
      method: "post",
      url,
      data
    });
  }

  put(url, data, config = {}) {
    return this.handleRequest({
      ...config,
      method: "put",
      url,
      data
    });
  }

  patch(url, data, config = {}) {
    return this.handleRequest({
      ...config,
      method: "patch",
      url,
      data
    });
  }
}

const http = new HTTPHandler();

export default http;
