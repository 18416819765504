import React, {useEffect, useState} from "react";
import {Table, Tag} from "antd";
import api from "../../../../api";

const {Column} = Table;

const OutstandingTable = ({userId}) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect( () => {
    initData();
  }, []);

  const initData = async () => {
    setLoading(true);
    const {response, error} = await api.report.outstanding(userId);
    if(!error) {
      setData(response?.items ?? [])
    }
    setLoading(false)
  }

  return (<div>
    <Table
      rowKey='id' size="small" bordered className='mt-4 ' loading={loading}
      dataSource={data}
      pagination={false}
    >

      <Column title='Date' dataIndex='created_at' key='created_at' align='center'></Column>

      <Column title='Name' key='name' align='center'
              render={(_, record) => {
                return (
                  <a className='underline'>{record?.user?.username ?? ''}</a>
                )
              }}></Column>

      <Column title='Poll' dataIndex='poll' key='poll' align='center'></Column>
      <Column title='Range' dataIndex='ranges' key='ranges' align='center'></Column>
      <Column title='Type' dataIndex='type' key='type' align='center'
              render={(_, record) => {
                return (
                  <span className='flex flex-wrap pr-0 mr-0'>
                          <Tag color="magenta">{record.type}</Tag>
                    {
                      record.jackpot ? <Tag color="gold">jackpot</Tag> : ''
                    }
                        </span>
                )
              }}></Column>


      <Column title='Amount' dataIndex='amount' key='amount' fixed='right' align='right'
              render={text =>
                <span>{
                  Number(text).toLocaleString()
                }</span>
              }></Column>
    </Table>
  </div>);
}
export default OutstandingTable;