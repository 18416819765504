import React, {createContext, useContext, useEffect, useState} from 'react'
import api from "../api";
import { unAuthenticateService } from "../service/UnauthenticateService";


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [loading, setLoading] = useState(false)
  const [isAuthenticate, setAuthenticate] = useState(true)
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSub, setSub] = useState(true);
  const [userRole, setUserRole] = useState();
  const [balance, setBalance] = useState(false);

  const [canAccount, setCanAccount] = useState(false)
  const [canCash, setCanCash] = useState(false)

  const [onlineUsers, setOnlineUsers] = useState()
  const [onlineUserCount, setOnlineUserCount] = useState()

  const logout = async () => {
    setLoading(true);
    await api.auth.logOut()
    localStorage.clear();
    removeAllCookie();
    window.location.reload()
  }


  useEffect(() => {
    resetOldToken()
    initial();
    unAuthenticateObserve()
    initActiveUserOnline()
  }, [])

  const resetOldToken = async () => {
    var reset = false;
    let cookieArray = document.cookie.split(";");
    for(var i = 0; i < cookieArray.length; i++) {
      let cookiePair = cookieArray[i].split("=");
      if (cookiePair[0].trim() == 'resettoken') {
        reset = decodeURIComponent(cookiePair[1])
      }
    }
    if(reset != 2) {
      removeAllCookie();
      document.cookie = `resettoken=2; expires=1000000000; Path=/`;
    }
  }

  const removeAllCookie = () => {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  }

  const reloadUser = async () => {
    const {response, error} = await api.user.detail();
    setUser(!error ? response : null)
    setIsAdmin(response?.user?.roles[0]?.name === 'admin' ?? false)
    setUserRole(response?.user?.roles[0]?.name)
    setBalance(response?.user?.credit?.balance ?? 0)
    setUser(response?.user)
    setSub(response?.is_sub)
    setPermission(response?.sub_permission)
  }

  const initial = async () => {
    if (window.location.protocol !== 'https:' && process.env.NODE_ENV === "production") {
      window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }

    setLoading(true)
    const {response, error} = await api.user.detail();
    setAuthenticate(!error)
    setUser(!error ? response : null)
    setLoading(false)
    setIsAdmin(response?.user?.roles[0]?.name === 'admin' ?? false)
    setUserRole(response?.user?.roles[0]?.name)
    setBalance(response?.user?.credit?.balance ?? 0)
    setUser(response?.user)
    setSub(response?.is_sub)
    setPermission(response?.is_sub, response?.sub_permission)
  }

  const setPermission = (isSub, permissions) => {
    if(!isSub) {
      setCanCash(true)
      setCanAccount(true)
    } else if (permissions) {
      setCanCash(permissions.includes('sub_cash'))
      setCanAccount(permissions.includes('sub_account'))
    }
  }

  const initActiveUserOnline = async () => {
    const {response, error} = await api.dashboard.userOnline()
    if(!error) {
      setOnlineUsers(response)
      setOnlineUserCount(response.length)
    }
    setInterval(async function () {
      if(isAuthenticate) {
        const {response, error} = await api.dashboard.userOnline()
        if(!error) {
          setOnlineUsers(response)
          setOnlineUserCount(response.length)
        }
      }
    }, 20 * 1000);
  }

  const unAuthenticateObserve = () => {
    unAuthenticateService.getUnAuthenticate().subscribe(() => {
      setAuthenticate(false)
    });
  }

  return (
    <AuthContext.Provider value={{
      reloadUser, isAuthenticate, logout, loading, user, isAdmin, balance, isSub,
      canCash, canAccount, onlineUsers, onlineUserCount, userRole
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)