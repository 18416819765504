import http from "../http";

export default (axios, base) => ({
  list: () => http.get(`${base}`),
  create: (data)  => http.post(`${base}`, data),
  // update: (userId, data)  => http.put(`${base}/update/${userId}`, data),
  // updateStatus: (status, userId)  => http.put(`${base}/update/status/${userId}`, {status: status}),
  // deposit: (data) => http.post(`${base}/deposit`, data),
  // withdraw: (data) => http.post(`${base}/withdraw`, data),
});
