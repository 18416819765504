import http from "../http";

export default (axios, base) => ({
  cash: (start, end, userId, searchName = '', searchLoginId = '', page = 1, pageSize = 50) => {
    return http.get(`${base}/cash?start_date=${start}&end_date=${end}${userId ? ('&user_id=' + userId) : ''}&s_name=${searchName}&s_login_id=${searchLoginId}&page=${page}&per_page=${pageSize}`);
  },
  cashDetail: (start, end, userId) => {
    return http.get(`${base}/cash/detail/${userId}?start_date=${start}&end_date=${end}`);
  },
  winlose: (start, end, userId, searchName = '', searchLoginId = '', page = 1, pageSize = 50) => {
    return http.get(`${base}/win_lose?start_date=${start}&end_date=${end}${userId ? ('&user_id=' + userId) : ''}&s_name=${searchName}&s_login_id=${searchLoginId}&page=${page}&per_page=${pageSize}`)
  },
  winLoseDetail: (start, end, userId, page = 1, pageSize = 50) => {
    return http.get(`${base}/win_lose/detail/${userId}?start_date=${start}&end_date=${end}&page=${page}&per_page=${pageSize}`);
  },
  outstanding: (userId) => {
    return http.get(`${base}/outstanding/${userId}`);
  },
  oldcash: (start, end, userId, searchName = '', searchLoginId = '', page = 1, pageSize = 50) => {
    return http.get(`${base}/cash?start_date=${start}&end_date=${end}${userId ? ('&user_id=' + userId) : ''}&s_name=${searchName}&s_login_id=${searchLoginId}&page=${page}&per_page=${pageSize}&year=2023`);
  },
  oldwinlose: (start, end, userId, searchName = '', searchLoginId = '', page = 1, pageSize = 50) => {
    return http.get(`${base}/win_lose?start_date=${start}&end_date=${end}${userId ? ('&user_id=' + userId) : ''}&s_name=${searchName}&s_login_id=${searchLoginId}&page=${page}&per_page=${pageSize}&year=2023`)
  },
  oldWinLoseDetail: (start, end, userId, page = 1, pageSize = 50) => {
    return http.get(`${base}/win_lose/detail/${userId}?start_date=${start}&end_date=${end}&page=${page}&per_page=${pageSize}&year=2023`);
  },
});
