import http from "../http";

export default (axios, base) => ({
  accessLevel: () => http.get(`${base}/access_level`),
  list: (type, searchName, searchLoginId) => http.get(`${base}/list?type=${type}&s_name=${searchName}&s_login_id=${searchLoginId}`),
  filter: (userId) => http.get(`${base}/list/filter/${userId}`),
  create: (data)  => http.post(`${base}/create`, data),
  update: (userId, data)  => http.put(`${base}/update/${userId}`, data),
  updateStatus: (status, userId)  => http.put(`${base}/update/status/${userId}`, {status: status}),
  deposit: (data) => http.post(`${base}/deposit`, data),
  withdraw: (data) => http.post(`${base}/withdraw`, data),
  letter: (search) => http.get(`${base}/letter?search=${search}`)
});
