import http from "../http";

export default (axios, base) => ({
  maintenance: () => http.get(`${base}/maintenance`),
  setMaintenance: (data) => http.put(`${base}/maintenance`, data),
  time: () => http.get(`${base}/time`),
  setTime: (data) => http.put(`${base}/time`, data),
  jackpot: () => http.get(`${base}/jackpot`),
  setJackpot: (data) => http.put(`${base}/jackpot`, data),
  jackpotBetLimit: () => http.get(`${base}/jackpotBetLimit`),
  setJackpotBetLimit: (data) => http.put(`${base}/jackpotBetLimit`, data),
  forceJackpot: () => http.get(`${base}/forceJackpot`),
  setForceJackpot: (data) => http.put(`${base}/forceJackpot`, data),
  forceMiniJackpot: () => http.get(`${base}/forceMiniJackpot`),
  setForceMiniJackpot: (data) => http.put(`${base}/forceMiniJackpot`, data),
  autoApprove: () => http.get(`${base}/autoApprove`),
  setAutoApprove: (data) => http.put(`${base}/autoApprove`, data),
  calculate: (query) => http.get(`${base}/calculate${query ? '?' + query : ''}`),
  setCalculate: (data) => http.put(`${base}/calculate`, data),
  betAmount: () => http.get(`${base}/betAmount`),
  setBetAmount: (data) => http.put(`${base}/betAmount`, data)
});
