import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'km',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          dashboard: 'Dashboard',
          userOnlineN: 'User Online {{onlineUserCount}}',
          jackpot: 'Jackpot',
          report: 'Report',
          cash: 'Cash',
          winLose: 'Win/Lose',
          depositWithdraw: 'Deposit/Withdraw',
          account: 'Account',
          list: 'List',
          sub: 'Sub',
          group: 'Group',
          approve: 'Approve',
          admin: 'Admin',
          setting: 'Setting',
          game: 'Game',
          location: 'Location',
          system: 'System',
          activity: 'Activity',
          changePassword: 'Change Password',
          logout: 'Logout',

          search: 'Search',
          today: 'Today',
          yesterday: 'Yesterday',
          this_week: 'This Week',
          last_week: 'Last Week',
          this_month: 'This Month',
          last_month: 'Last Month',
          create: 'Create',

          password: 'Password',
          change_password: 'Change Password',
          old_password: 'Old Password',
          new_password: 'New Password',
          confirm_password: 'Confirm Password',
          submit_change_password: 'Submit',

          name: 'Name',
          login_id: 'Login ID',
          created_at: 'Created At',
          browser: 'Browser',
          platform: 'Platform',

          date: 'Date',
          poll: 'Poll',
          type: 'Type',
          amount: 'Amount',
          win: 'Win',
          paid: 'Paid',

          status: 'Status',
          beginning: 'Beginning',
          turn_over: 'Turnover',
          net_wl: 'Net W/L',
          deposit: 'Deposit',
          withdraw: 'Withdraw',
          balance: 'Balance',
          function: 'Function',

          active: 'Active',
          inactive: 'InActive',

          submit: 'Submit',
          your_balance: 'Your balance',
          user_balance: 'User balance',
          deposit_amount: 'Deposit amount',
          withdraw_amount: 'Withdraw amount',

          tax: 'Tax',

          log: 'Log',
          member_online: 'Member Online',
          total: 'Total'
        }
      },
      km: {
        translation: {
          dashboard: 'ទំព័រដើម',
          userOnlineN: 'គណនី កំពុងដំណើរការ {{onlineUserCount}}',
          jackpot: 'ជេកផត',
          report: 'របាយការណ៍',
          cash: 'សាច់ប្រាក់',
          winLose: 'ឈ្នះ/ចាញ់',
          depositWithdraw: 'ដាក់/ដក សាច់ប្រាក់',
          account: 'គណនី',
          list: 'បញ្ជី',
          sub: 'Sub',
          group: 'ក្រុម',
          approve: 'ដំណើរការ',
          admin: 'Admin',
          setting: 'ការកំណត់',
          game: 'ហ្គេម',
          location: 'ទីតាំង',
          system: 'ប្រព័ន្ធ',
          activity: 'សកម្មភាព',
          changePassword: 'ប្តូរពាក្យសម្ងាត់',
          logout: 'ចាកចេញ',

          search: 'ស្វែងរក',
          today: 'ថ្ថៃនេះ',
          yesterday: 'ម្សិលមិញ',
          this_week: 'សប្ដាហ៏នេះ',
          last_week: 'សប្ដាហ៏មុន',
          this_month: 'ខែនេះ',
          last_month: 'ខែមុន',
          create: 'បង្កើតថ្មី',

          password: 'លេខសំងាត់',
          change_password: 'ផ្លាស់ប្ដូរ លេខសំងាត់',
          old_password: 'លេខសំងាត់ចាស់',
          new_password: 'លេខសំងាត់ថ្មី',
          confirm_password: 'លេខសំងាត់ថ្មីម្ដងទៀត',
          submit_change_password: 'ស្នើរផ្លាស់ប្ដូរ',

          name: 'ឈ្មោះ',
          login_id: 'លេខសម្គាល់',
          created_at: 'ថ្ងៃបង្កើត',
          browser: 'កម្មវិធី',
          platform: 'ប្រភេទ',

          date: 'ថ្ងៃខែ',
          poll: 'Poll',
          type: 'ប្រភេទ',
          amount: 'សាច់ប្រាក់',
          win: 'ឈ្នះ',
          paid: 'បង់សាច់ប្រាក់',

          status: 'ដំណើរការ',
          beginning: 'Beginning',
          turn_over: 'ផ្នាល់សរុប',
          net_wl: 'Net W/L',
          deposit: 'ដាក់ប្រាក់',
          withdraw: 'ដកប្រាក់',
          balance: 'សាច់ប្រាក់',
          function: 'មុខងារ',

          active: 'ដំណើរការ',
          inactive: 'បិតដំណើរការ',

          submit: 'បញ្ជូន',
          your_balance: 'ចំណួនសាច់ប្រាក់របស់អ្នក់',
          user_balance: 'ចំណួនសាច់ប្រាក់គណនី',
          deposit_amount: 'ដាក់ប្រាក់ចំណួន',
          withdraw_amount: 'ដក់ប្រាក់ចំណួន',

          tax: 'ពន្ធ',
          log: 'កំណត់ហេតុ',
          member_online: 'សមាជិកបានចូលកម្មវិធី',
          total: 'សរុប'
        }
      }
    }
  });

export default i18n;