import React, {useEffect, useState} from "react";
import {Layout, Dropdown, Typography, Avatar} from 'antd';
import {useAuth} from "../../../hooks/AuthContext";
import type { MenuProps } from 'antd';
import { useLocation } from "react-router";
import i18n from "../../../i18n";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {LogoutOutlined, UnlockOutlined} from "@ant-design/icons";


const { Header } = Layout;

const HeaderLayout = () => {

  const { logout, user, isAdmin, balance } = useAuth()
  const location = useLocation();
  const [appBarTitle, setAppBarTitle]  = useState<String>();
  const [langCode, setLang] = useState<String>();
  const {t} = useTranslation();


  const userItems: MenuProps['items'] = [
    {
      label: t('change_password'),
      key: '3',
      icon: <UnlockOutlined />
    },
    {
      label: t('logout'),
      key: '4',
      icon: <LogoutOutlined />
    },
  ];

  const languageItems: MenuProps['items'] = [
    {
      label: 'ភារសារខ្មែរ',
      key: 'km',
      icon: <img src='/flag-kh-icon.svg' width={25}/>
    },
    {
      label: 'English',
      key: 'en',
      icon: <img src='/flag-us-icon.svg' width={25}/>
    },
  ];

  const getTitleAppbar = (path: string) => {
    if(path == '/') return 'Home';
    if(path.includes('report/cash')) return 'Cash';
    if(path.includes('report/winlose')) return 'Win Lose';
    if(path.includes('account/list')) return 'Account';
    if(path.includes('account/approve')) return 'Approval';
    if(path.includes('setting/game')) return 'Game Setting';
    if(path.includes('setting/jackpot')) return 'Jackpot Setting';
    if(path.includes('user/change_password')) return 'Change Password';
    return ''
  }

  useEffect(() => {
    setLang(i18next.language ?? 'km');
  },[])
  useEffect(() => {
    setAppBarTitle(getTitleAppbar(location.pathname));

  }, [location])

  const onClick: MenuProps['onClick'] = ({ key }) => {
    logout()
  };

  const onClickChangeLanguage: MenuProps['onClick'] = ({ key }) => {
    setLang(key)
    i18n.changeLanguage(key).then(r => {

    });
  };

  function capitalizeFirstLetter(text: String) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }



  return (
      <Header className="pl-5 pr-5 h-12 fixed z-10 w-screen shadow-lg justify-center align-middle"
              style={{ minWidth: 1287, background: '#D70F21'}}>
        <div className='flex flex-row float-left items-center'>
          <div>
            <img src='/flat-logo.png' width={80}/>
          </div>
          <div className='flex flex-row items-center bg-red-400 h-5 leading-none rounded-lg pl-2 ml-24 pr-2' style={{ minWidth: 85}}>
            <img src='/riel_circle.png' width={22} style={{ marginLeft: -12 }} />
            <div className='text-white ml-1'>{Number(balance).toLocaleString()}</div>
          </div>
        </div>

        <div className='float-right flex flex-row h-12 justify-center align-middle'>
          <Dropdown className='float-right hover:bg-red-500'  menu={{ items: userItems, onClick }} >
            <div className='flex flex-row justify-center p-2 rounded-lg' style={{height: 40, marginTop: 4, paddingTop: 4}}>
              <Avatar src='/user_icon_2.png' size={22} style={{marginTop: 1}}></Avatar>
              <Typography.Title level={5} className='ml-2 text-[#E8E8E8FF]'>
                {/*{capitalizeFirstLetter(user?.username ?? 'unknown')}*/}
                {user?.username?.toUpperCase() ?? 'UNKNOWN'}
              </Typography.Title>
            </div>
            {/*<Space style={{marginTop: -10}}>*/}
            {/*  <span>test</span>*/}
            {/*<Text className='text-white'>{Number(balance).toLocaleString()}</Text>*/}
            {/*<Button type="primary" icon={<UserOutlined style={{ color: '#fff'}}/>} />*/}
            {/*</Space>*/}
          </Dropdown>

          <Dropdown className='float-right hover:bg-red-500 ml-2'
                    menu={{ items: languageItems, onClick: onClickChangeLanguage }}
                    overlayStyle={{width: 150}}>
            <div className='flex flex-row justify-center p-2 rounded-lg' style={{height: 40, marginTop: 4, paddingTop: 4}}>
              <img src={langCode == 'km' ? '/flag-kh-icon.svg' : '/flag-us-icon.svg'} style={{marginTop: 1}}
                   width={langCode == 'km' ? 35 : 25}></img>
            </div>
          </Dropdown>
        </div>

      </Header>
  )
}

export default HeaderLayout