import MobileIndex from "../Mobile";
import WebIndex from "../Web";

const DelegatePage = () => {

  // const isMobile = window.innerWidth < 610;

  const isMobile = false;
  
  return isMobile ? <MobileIndex/> : <WebIndex/>
}

export default DelegatePage;
